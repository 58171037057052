@import "~@/styles/themes.scss";
.primaryTextBtn {
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 60px;
  border: none;
  color: $admin-accent-blue;

  &:hover {
    color: $admin-secondary-color;
  }

  &:active {
    color: $admin-grey-1;
  }
}

.smallTextBtn {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  border: none;
  color: $admin-accent-blue;

  &:hover {
    color: $admin-secondary-color;
  }

  &:active {
    color: $admin-grey-1;
  }
}

.largeSubmitButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 60px;
  font-size: 18px;
  width: 100%;
  color: #fff;
  background-color: $admin-secondary-color;
  box-shadow: none;
  border-color: $admin-secondary-color;
  &:focus,
  &:hover {
    border: 2px solid $admin-secondary-tint-2 !important;
    box-shadow: none;
    background-color: $admin-secondary-tint-2;
    border-color: $admin-secondary-tint-2;
    color: #fff;
  }

  &:hover {
    border: 2px solid $admin-secondary-tint-2 !important;
    box-shadow: none;
    background-color: $admin-secondary-tint-2;
    border-color: $admin-secondary-tint-2;
    color: #fff;
  }

  :global {
    .ant-input-number-focused {
      border: 2px solid $admin-secondary-color !important;
      box-shadow: none;
      border-color: $admin-secondary-color;
    }
  }
}

.eventAvailabilityBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 18px;
  width: 100%;

  color: #fff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1), 0px 4px 5px rgba(0, 0, 0, 0.1),
    0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  border: 3px solid #ffffff;

  &:focus,
  &:hover {
    border: 3px solid white !important;
    box-shadow: none;
    background-color: $admin-secondary-tint-2;
    opacity: 0.5;
    border-color: white;
    color: #fff;
  }

  &:hover {
    border: 2px solid #ffffff !important;
    box-shadow: none;
    background-color: $admin-secondary-tint-2;
    border-color: $admin-secondary-tint-2;
    color: #fff;
  }

  &:nth-child(2) {
    background-color: #fb4953 !important;
  }

  :global {
    .ant-input-number-focused {
      border: 2px solid $admin-secondary-color !important;
      box-shadow: none;
      border-color: $admin-secondary-color;
    }
  }
}


.eventRejectBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  font-weight: bold;
  font-size: 18px;
  width: 100%;

  color: #fff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1), 0px 4px 5px rgba(0, 0, 0, 0.1),
    0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  border: none;

  &:focus,
  &:hover {
    border: 3px solid white !important;
    box-shadow: none;
    background-color: $admin-secondary-tint-2;
    opacity: 0.5;
    border-color: white;
    color: #fff;
  }

  &:hover {
    border: 2px solid #ffffff !important;
    box-shadow: none;
    background-color: $admin-secondary-tint-2;
    border-color: $admin-secondary-tint-2;
    color: #fff;
  }

  &:nth-child(2) {
    background-color: #fb4953; //!important
  }
}
