// Admin color themes
$admin-primary-color: #000c10;
$admin-secondary-color: #fcea25;
$admin-tertiary-color: #132257;
$admin-secondary-tint-1: #ffdfb9;
$admin-secondary-tint-2: #d4c11c;
$admin-grey-1: #1b1b1b;
$admin-grey-2: #595959;
$admin-grey-3: #8c8c8c;
$admin-grey-4: #bfbfbf;
$admin-grey-5: #dadada;
$admin-grey-6: #eaeaea;
$admin-grey-7: #f5f5f5;
$admin-accent-red: #fb4953;
$admin-accent-blue: #1890ff;
$admin-accent-yellow: #faad14;
$admin-accent-green: #52c41a;
$admin-status-red: #f5222d;
$admin-main-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08),
  0 1px 18px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.1);

// PWA color theme
$pwa-primary-color: #000c10;
$pwa-secondary-color: #fcea25;
$pwa-grey-1: #1b1b1b;
$pwa-grey-2: #595959;
$pwa-grey-3: #8c8c8c;
$pwa-grey-4: #bfbfbf;
$pwa-grey-5: #dadada;
$pwa-grey-6: #eaeaea;
$pwa-grey-7: #f5f5f5;
$pwa-accent-red: #fb4953;
$pwa-accent-blue: #1890ff;
$pwa-accent-yellow: #d4c11c;
$pwa-accent-green: #52c41a;
$pwa-status-red: #f5222d;

$pwa-status-red: #f5222d;

// Fonts

$admin-title-font: 'Raleway';
$primary-font: 'Muli';
$secondary-font: 'Raleway';

$dyslexie-font: 'Dyslexie';

$sign-up-color: #eaf6fd;
$sign-up-text-color: #1990ff;
$near-black: #0a0b09;