@import "~@/styles/themes.scss";
.container{
  height: 100vh;
  width: 100vw;

  .backgroundLogo{
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    width: 900px;
    opacity: 0.2;
  }

  .backgroundImage{
    width: 900px;
  }
}

.main{
  margin-top: 200px;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  .leftContainer{
    // align-items: center;
    // justify-content: center;
    .offsideImage{
      width: 320px;
    }
  }
  .rightContainer{
    // background-color: rebeccapurple;
    align-items: center;
    justify-content: center;

    .title{
      font-family: $secondary-font;
      font-size: 60px;
      font-weight: 800;
      color: $admin-grey-1;
      line-height: 70px;
      margin-bottom: 25px;
    }

    .link{
      margin-bottom: 40px;
      // display: flex;
      // flex-direction: column;

      .linkText{
        font-family: $secondary-font;
        font-size: 20px;
        color: $admin-accent-blue;
        margin-bottom: 20px;
        text-decoration: underline;
        cursor: pointer;
        width: max-content;
      }
    }

    .box{
      background-color: lightgray;
      padding: 20px;
      border-radius: 20px;

      .reportTitle{
        font-family: $secondary-font;
        font-size: 16px;
        font-weight: 700;
        color: $admin-grey-1;
        margin-bottom: 10px;
      }

      .reportDescription{
        font-family: $secondary-font;
        font-size: 16px;
        color: $admin-grey-1;
        margin-bottom: 10px;
      }

      .reportButton{
        background-color: $admin-grey-1;
        color: yellow;
        font-family: $secondary-font;
        font-size: 16px;
        margin-bottom: 10px;
        display: inline-block;
        padding: 10px;
        border-radius: 7px;
        cursor: pointer;
      }
    }
    .mobileLink{
      display: none;
    }
  }
}

@media (max-width: 576px) {
  .container{

    .backgroundLogo{
      position: absolute;
      top: 30px;
      left: 0;
      right: 0;
      margin-right: auto;
      margin-left: auto;
      width: 300px;
      opacity: 0.2;
    }
  
    .backgroundImage{
      width: 300px;
    }
  }
  .main{
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 70px;
  }
  
  .leftContainer {
      .offsideImage{
        width: 105px !important;
      }
      .offsideContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
      }
  }
  

  .rightContainer{
    // align-items: center;
    // justify-content: center;

    .title{
      font-size: 32px !important;
      font-weight: 800;
      color: $admin-grey-1;
      line-height: 40px !important;
      margin-bottom: 25px;
      text-align: center;
    }

    .link{
      display: none;
      margin-bottom: 40px;
    }

    .box{

      .reportTitle{
        font-family: $secondary-font;
        font-size: 16px;
        font-weight: 700;
        color: $admin-grey-1;
        margin-bottom: 10px;
      }

      .reportDescription{
        font-family: $secondary-font;
        font-size: 16px;
        color: $admin-grey-1;
        margin-bottom: 10px;
        text-align: center;
      }

      .reportButton{
        background-color: $admin-grey-1;
        color: yellow;
        font-family: $secondary-font;
        font-size: 16px;
        margin-bottom: 10px;
        display: inline-block;
        padding: 10px;
        border-radius: 7px;
        width: 100%;
        text-align: center;
        cursor: pointer;
      }
    }
    .mobileLink{
      display: block !important;
      // justify-content: center;
      // align-items: center;
      font-family: $secondary-font;
      font-size: 16px;
      color: $admin-accent-blue;
      cursor: pointer;
      // width: max-content;
      text-align: center;
      margin-top: 40px;
    }
  }
}

@media (min-width: 992px) {
  .leftContainer {
    .offsideImage{
      max-width: 250px !important;
    }
    .offsideContainer{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
    }
  }
}
@media (min-width: 1440px) {
  .leftContainer {
    .offsideImage{
      max-width: 320px !important;
    }
    .offsideContainer{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
    }
  }
}